body {
  margin: 0;
  padding: 0;
}
:root {
  --colorA: #3f51b5;
  --colorAA: #0d006e;
  --colorAAA: #090050;
  --colorB: #b6b3ff;
  --colorC: #776cff;
  --colorD: #f8047e;
  --colorE: #f7589f;
  --lightPrime: #f0f0ff;
  --lightDark: rgb(221, 221, 221);
  --fadeBlack: rgb(160, 160, 160);
  --layoutWidth: 1400px;
}
/* utils */
.d-flex {
  display: flex;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.position-absolute {
  position: absolute !important;
  z-index: 1000;
}
.buttonParent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  margin: 0 auto;
}
.buttonChild {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/*  */
.form {
  width: 100%;
  margin-top: 1%;
}
.paper {
  margin: 8rem 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  margin: 1% auto !important;
  background-color: rgb(243, 9, 9) !important;
}
.logo_style {
  width: 100px;
}
.logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.forgetSubmit {
  margin: 8px 0px !important;
}
.circularProgress {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.card {
  border-radius: 0.5rem !important;
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5) !important;
}
.Card {
  border-radius: 0.5rem !important;
  box-shadow: 0 7px 10px -10px rgba(150, 170, 180, 0.5) !important;
}

tbody tr:hover {
  background-color: #ddd9d94f;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.AdminDashboardChart {
  box-shadow: 0 5px 15px #c0b7b7 !important;
  border-radius: 0.5rem !important;
}

.StoreManagerTable {
  min-width: 100% !important;
}
.feedbackPaper {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  background: #eee !important;
  box-shadow: 0 8px 8px -4px lightblue !important;
}
.marketPlaceDailog {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  background: #eee !important;
}

.B2CaddForm {
  margin: 0rem 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.B2BaddForm {
  margin: 0rem 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AddStoreManagerPaper {
  margin: 0rem 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.StoreManagerPaper {
  margin: 0rem 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StoreWorkerEvaluateForm {
  width: 100%;
  margin-top: 2%;
}

.StoreWorkerCardDesign {
  box-shadow: 0 0 11px rgb(53, 53, 53) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workerevaluatedappBar {
  position: "relative !important";
}

.StoreWorkerDailodCardDesign {
  box-shadow: 0 0 11px rgb(105, 102, 102) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.workerEvaluatedardImage {
  height: 90px;
  width: 100px;
}

.evaluatedCarFont {
  font-weight: 600 !important;
  text-transform: capitalize;
}

.EditStoreWorkerEvaluatedCarAddNewButton {
  display: "flex";
  justify-content: "center";
  align-items: "center";
}

.card-container {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 6px;
  top: 50%;
  left: 50%;
  box-shadow: 0px 1px 10px 1px #000;
  overflow: hidden;
  display: inline-block;
}
.upper-container {
  height: 30%;
  background: #3f51b5;
}
.lower-container {
  height: 70%;
  background: rgb(221, 211, 215);
  padding: 20px;
  padding-top: 40px;
  text-align: center;
}
.image-container {
  background: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 2px;
  transform: translate(250px, 90px);
}
.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.h3,
h4 {
  box-sizing: border-box;
  line-height: 0.6;
  font-weight: lighter;
}
.h4 {
  opacity: 0.6;
  font-weight: bold;
}
.p {
  font-size: 16px;
  color: gray;
  margin-bottom: 2.5rem;
}
.rightCard-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2rem;
  padding: 15px;
}

.popupConform {
  padding: 1em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  text-align: center;
}

.img-editable {
  /* width: 100px; */
  height: 100px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.126);
  margin: 1.5vh 0;
  transition: 0.5s;
}
.img-editable:hover {
  box-shadow: 1px 1px 10px rgba(253, 140, 140, 0.6);
  transform: scale(1.1);
}
.img-editable-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* Spinny Section */
.BiddingAmountWarper {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border-radius: 0.5em;
  gap: 1em;
}
/* .BiddingAmountWarper input {
  border-radius: 0.5em;
  font-size: 1.25em;
  padding: 0.25em 1em;
  border: 2px solid #3f51b5;
  width: 100%;
  text-align: center;
} */
/* .BiddingAmountWarper button {
  width: 100%;
  background-color: #3f51b5;
  color: white;
  border: none;
  font-size: 1em;
  border-radius: 0.5em;
  padding: 0.25em;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* custom loader */
/* loaderOne */
.loaderOneWhite {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 0.5em;
}
.loaderOneWhite::before {
  content: "";
  min-height: 1em;
  min-width: 1em;
  max-height: 16em;
  max-width: 16em;
  background-color: transparent;
  border: 2.5px solid white;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotate cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite 1s;
}
/* loaderOne */
.loaderOneBlue {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--colorA);
  gap: 0.5em;
}
.loaderOneBlue::before {
  content: "";
  min-height: 1em;
  min-width: 1em;
  max-height: 16em;
  max-width: 16em;
  background-color: transparent;
  border: 2.5px solid var(--colorA);
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotate cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite 1s;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
button[disabled] {
  /* opacity: 0.8; */
  filter: grayscale(0.5);
}
button[disabled],
button[disabled]:hover,
button[disabled]:active {
  cursor: not-allowed;
  filter: grayscale(0.5);
}

[disabled] {
  cursor: not-allowed;
}
/* error message */
.EMS {
  color: red;
  font-size: 0.8em;
  display: flex;
}
.EMS::before {
  content: "*";
}

/* text Capitalized */
.TCP {
  text-transform: capitalize;
}
/* cursor pointer */
.CP,
.CP * {
  cursor: pointer;
}

/* flex Row center and space Evenly */
.FRSA {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
/* flex vertical center and space between */
.FRSB {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
/* flex column center and Gap 0.5em*/
.FCGP05 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  width: 100%;
}
/*flex vertical column Center */
.FCVC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
/* padding 0.5em */
.PD05 {
  padding: 0.5em;
}
/* style title under line */
.STUL {
  position: relative;
  padding: 0.25em 0em;
  margin: 0.5em 0em;
}
.STUL::after {
  content: "";
  position: absolute;
  height: 0.1em;
  border-radius: 1em;
  width: 80%;
  bottom: 0;
  left: 0;
  background-color: var(--colorA);
}

.OF_Y {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* StoreManagerBiddingCars  */
.priseSectionWarper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

/*
evaluated data file upload section
*/
.evaluatedFileUploadWarper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0rem;
  background-color: white;
  padding: 0.5rem 0rem;
}
.evaluatedFileUploadLabel {
  padding: 0.5rem 1rem;
  background-color: var(--colorA);
  color: white;
  border-radius: 0.25rem;
  cursor: pointer;
}
.evaluatedFileUploadButton {
  padding: 0.5rem 1rem;
  background-color: var(--colorA);
  color: white;
  border-radius: 0.25rem;
  cursor: pointer;
  border: none;
}
